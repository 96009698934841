/* color  */
.a {
	text-decoration: none;
}

.bg-custom {
	background-color: #005b48;
}

.mbv5 {
	margin-bottom: 50vh;
}

.color-white {
	color: white;
}

.text-styled{
	font-family: cursive;	
}

.naver {
	display: flex;
	justify-content: space-between;
}

.bread {
	display: flex;
	height: 70vh;
	padding: 20px;
	background-color: #ffffff;
}

.bread-left {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 10px;
	justify-content: center;
	text-align: center;
	justify-items: center;
}

.bread-left h1,
.bread-left span {
	font-size: 50px;
	font-family: cursive;
	font-weight: 900;
	margin-top: 10vh;
	text-align: center;
	color: #fdfdfd;
	font-style: italic;
	/* font-size: 32px; */
}

.bread-left h1 span {
	color: #005b48;
}

.bread-left img {
	height: 100px;
	width: 100px;
	margin-left: 60px;
	margin-top: 10px;
}

.bread-right {
	display: flex;
	flex: 2;
	justify-content: center;
	text-align: center;
}

.bread-right img {
	margin-top: 20px;
	height: 50vh;
	width: 50vw;
}

.bread {
	display: flex;
	height: 70vh;
	padding: 20px;
	background-color: #ffffff;
}

.bread-left {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 10px;
	justify-content: center;
	text-align: center;
	justify-items: center;
}

.bread-left h1,
.bread-left span {
	font-size: 50px;
	font-weight: 900px;
	margin-top: 10vh;
	text-align: center;
	font-style: italic;
	/* font-size: 32px; */
}

.bread-left h1 span {
	color: #005b48;
}

.bread-left img {
	height: 100px;
	width: 100px;
	margin-left: 60px;
	margin-top: 10px;
}

.bread-right {
	display: flex;
	flex: 2;
	justify-content: center;
	text-align: center;
}

.bread-right img {
	margin-top: 20px;
	height: 50vh;
	width: 50vw;
}

/* reality session  */

.reality {
	display: flex;
	height: 70vh;
	background-color: #ffffff;
}

.realit-left {
	display: flex;
	flex: 3;
}

.reality-right {
	display: flex;
	flex: 1;
	/* background-color: #c4fbef; */
	padding: 10px;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	justify-items: center;
}

.reality-left img {
	height: 100%;
	width: 100%;
}

.reality-right h1 {
	font-size: 30px;
	color: #105200;
	font-family: cursive;
	font-weight: 900px;
	text-align: center;
	margin-bottom: 30px;
	/* font-size: 32px; */
}

.reality p {
	text-align: justify;
}

/* layers  */
.layer {
	display: flex;
	padding-top: 30px;
	justify-content: center;
	align-items: center;
	background-color: #005b48;
}

.counter {
	background-color: #aeaded;
}

/* footer section  */

footer {
	background-color: #005b48;
	color: white;
	padding-top: 20px;
}

footer p, footer h5 {
	text-align: center;
	font-family: cursive;
	font-size: 20px;
}

footer img{
	display: flex;
	width: 100px;
	height: 80px;
	margin-left: 30%;
	border-radius: 50%;
}

img .bt-logo {
	width: 100px;
	height: 80px;
	border-radius: 50%;
}

.icon-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
}

.icon-list li {
	margin-right: 20px;
	font-size: 14px;
	padding-top: 5px;
}


/************ product session  *********/

.product-card {
	background-color: #fff;
	border: none;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.image-container {
	position: relative;
}

.thumbnail-image {
	border-radius: 10px !important;
	object-fit: cover;
	width: 100%;
	height: 100%;
}


.discount {
	background-color: #005b48;
	padding-top: 1px;
	padding-bottom: 1px;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 10px;
	border-radius: 6px;
	color: #fff;
}

.wishlist {
	height: 25px;
	width: 25px;
	background-color: #eee;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.first {
	position: absolute;
	width: 100%;
	padding: 9px;
}


.dress-name {
	font-size: 13px;
	font-weight: bold;
	width: 75%;
}


.new-price {
	font-size: 13px;
	font-weight: bold;
	color: red;

}

.old-price {
	font-size: 8px;
	font-weight: bold;
	color: grey;
}


.btn {
	width: auto;
	height: auto;
	border-radius: 50%;
	padding: auto;
}

.creme {
	background-color: #fff;
	border: 2px solid grey;
}

.creme:hover {
	border: 3px solid grey;
}

.creme:focus {
	background-color: grey;

}


.red {
	background-color: #fff;
	border: 2px solid red;

}


.red:hover {
	border: 3px solid red;
}

.red:focus {
	background-color: red;
}



.blue {
	background-color: #fff;
	border: 2px solid #40C4FF;
}

.blue:hover {
	border: 3px solid #40C4FF;
}

.blue:focus {
	background-color: #40C4FF;
}

.darkblue {
	background-color: #fff;
	border: 2px solid #01579B;
}

.darkblue:hover {
	border: 3px solid #01579B;
}

.darkblue:focus {
	background-color: #01579B;
}

.yellow {
	background-color: #fff;
	border: 2px solid #FFCA28;
}

.yellow:hover {
	border-radius: 3px solid #FFCA28;
}

.yellow:focus {
	background-color: #FFCA28;
}


.item-size {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #fff;
	border: 1px solid grey;
	color: grey;
	font-size: 10px;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
}


.rating-star {
	font-size: 10px !important;
}

.rating-number {
	font-size: 10px;
	color: grey;

}

.buy {
	font-size: 12px;
	color: #659e56;
	font-weight: 500;
}

a .buy {
	text-decoration: none;
	padding: 5px;
	border: 1px solid green;
	border-radius: 5px;
}

.voutchers {
	background-color: #fff;
	border: none;
	border-radius: 10px;
	width: 190px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	overflow: hidden;

}

.voutcher-divider {
	display: flex;
}

.voutcher-left {
	width: 60%
}

.voutcher-name {
	color: grey;
	font-size: 9px;
	font-weight: 500;
}

.voutcher-code {
	color: red;
	font-size: 11px;
	font-weight: bold;
}

.voutcher-right {
	width: 40%;
	background-color: purple;
	color: #fff;
}

.discount-percent {
	font-size: 12px;
	font-weight: bold;
	position: relative;
	top: 5px;
}

.off {
	font-size: 14px;
	position: relative;
	bottom: 5px;
}

/* product listing  */

.ratings i {
	font-size: 16px;
	color: red
}

.strike-text {
	color: red;
	text-decoration: line-through
}

.product-image {
	width: 100%
}

.dot {
	height: 7px;
	width: 7px;
	margin-left: 6px;
	margin-right: 6px;
	margin-top: 3px;
	background-color: #659e56;
	border-radius: 50%;
	display: inline-block
}

.spec-1 {
	color: #938787;
	font-size: 15px
}

h5 {
	font-weight: 400
}

.para {
	font-size: 16px
}

/* login */
.wrapper {
	max-width: 500px;
	min-height: 500px;
	margin: 80px auto;
	padding: 40px 30px 30px 30px;
	background-color: #ecf0f3;
	border-radius: 15px;
	box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
	width: 80px;
	margin: auto;
}

.logo img {
	width: 100%;
	height: 80px;
	object-fit: cover;
	border-radius: 50%;
	box-shadow: 0px 0px 3px #5f5f5f,
		0px 0px 0px 5px #ecf0f3,
		8px 8px 15px #a7aaa7,
		-8px -8px 15px #fff;
}

.wrapper .name {
	font-weight: 600;
	font-size: 1.4rem;
	letter-spacing: 1.3px;
	padding-left: 10px;
	color: #555;
}

.wrapper .form-field input,
.wrapper .form-field textarea,
.wrapper .form-field select {
	width: 100%;
	display: block;
	border: none;
	outline: none;
	background: none;
	font-size: 1.2rem;
	color: #666;
	padding: 10px 15px 10px 10px;
	/* border: 1px solid red; */
}

.wrapper .form-field {
	padding-left: 10px;
	margin-bottom: 20px;
	border-radius: 20px;
	box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.wrapper .form-field .fas {
	color: #555;
}

.wrapper .btn {
	box-shadow: none;
	width: 100%;
	height: 40px;
	background-color: #005b48;
	color: #fff;
	border-radius: 25px;
	box-shadow: 3px 3px 3px #b1b1b1,
		-3px -3px 3px #fff;
	letter-spacing: 1.3px;
}

.wrapper .btn:hover {
	background-color: #659e56;
}

.wrapper a {
	text-decoration: none;
	font-size: 0.8rem;
	color: #659e56;
}

.wrapper a:hover {
	color: #659e56;
}


/* mobile  */
/* Media query for mobile devices */
@media only screen and (max-width: 768px) {

	.bread,
	.reality {
		flex-direction: column;
		align-items: center;
		height: auto;
	}

	.bread-left h1,
	.bread-left span {
		font-size: 20px;
		margin-top: 10vh;
	}

	.bread-left img {
		display: none;
	}

	.bread-left {
		margin-bottom: 20px;
	}

	.bread-right img {
		height: auto;
		width: 80%;
		/* Adjust the width as needed */
	}
}

@media(max-width: 380px) {
	.wrapper {
		margin: 30px 20px;
		padding: 40px 15px 15px 15px;
	}
}